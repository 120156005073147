import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`Design systems enable teams to build better products faster by making design reusable—reusability makes scale possible. This is the heart and primary value of design systems. A design system is a collection of reusable components, guided by clear standards, that can be assembled together to build any number of applications.`}</p>
    <p>{`By clearly documented code standards and best practices in place, designers and developers from across your organization can easily use and, more importantly, contribute to your design system.`}</p>
    <p>{`Regardless of the technologies and tools behind them, a successful design system follows these guiding principles:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`It’s consistent`}</strong>{`. The way components are built and managed follows a predictable pattern.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s self-contained`}</strong>{`. Your design system is treated as a standalone dependency.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s reusable`}</strong>{`. You’ve built components so they can be reused in many contexts.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s accessible`}</strong>{`. Applications built with your design system are usable by as many people as possible, no matter how they access the web.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s robust`}</strong>{`. No matter the product or platform to which your design system is applied, it should perform with grace and minimal bugs.`}</li>
    </ul>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p>{`This collection of design standards and associated rules can be addded to any html document by adding the following line of code inside the html `}<inlineCode parentName="p">{`<head>`}</inlineCode>{` tags.`}</p>
    <p><inlineCode parentName="p">{`<link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/gh/scudderuhsinc/UHStyles@master/beta/styles.min.css">`}</inlineCode></p>
    <p>{`Instructions : `}<a parentName="p" {...{
        "href": "https://www.w3schools.com/css/css_howto.asp"
      }}>{`https://www.w3schools.com/css/css_howto.asp`}</a></p>
    <p>{`A specific version of the design standards can be used by referencing the desired version number.`}</p>
    <p><inlineCode parentName="p">{`<link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/gh/scudderuhsinc/UHStyles@master/ - version number here - /styles.min.css">`}</inlineCode></p>
    <p>{`Instructions : `}<a parentName="p" {...{
        "href": "https://github.com/scudderuhsinc/UHStyles/blob/master/README.md"
      }}>{`https://github.com/scudderuhsinc/UHStyles/blob/master/README.md`}</a></p>
    <h3 {...{
      "id": "javascript"
    }}>{`JAVASCRIPT`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <p>{`Instructions :`}</p>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/ScudderStevens/pen/QWLJZVq"
      }}>{`https://codepen.io/ScudderStevens/pen/QWLJZVq`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      